








































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import handleBtn from '@/components/handleBtn/index.vue'
import { AsListInterface, AsDetailIterface, AsDetailItem, AsBlock } from '@/types/kyc'
import { getAssetsInfo, saveAssetItem } from '@/api/asKYC'
import { filterInsReqData, addInsDefault } from './../../../portfolio/portKYC/asComponents/tools'
import { cloneDeep } from 'lodash'
import { getInputValue } from '@/utils/validate'
import { CommonMudule } from '@/store/modules/common'
import { getcomplete } from '@/api/common'

@Component({
  name: 'drawInsCom',
  components: {
    handleBtn,
    moduleHeader
  }
})
export default class extends Vue {
  @Prop({ required: true }) private data!: AsListInterface
    private blockObj: AsBlock = {
      customerId: '',
      assetInputType: '',
      customerAssetSubList: null,
      customerMemberAssetVOList: [],
      parentAssetTypeId: '',
      parentAssetTypeName: ''
    }
      private inputData = this.data
      private value = 0
      dialog = false
      loading = false

      get curencyData() {
        return CommonMudule.curencyData
      }
      blurGetVal(blockIndex:number, index:number, idx:number, val: string) {
        if (!val) {
          this.blockObj.customerMemberAssetVOList[blockIndex].customerAssetSubList[index].customerAssetList[idx].isClear = true
        }
        const e:Event = window.event as Event
        const value = getInputValue(e)
        this.blockObj.customerMemberAssetVOList[blockIndex].customerAssetSubList[index].customerAssetList[idx].assetAmount = value
      }

      private options2 = [
        {
          value: 'currency-id-0001',
          label: '人民币RMB'
        }
      ]
      get options() {
        return CommonMudule.curencyData
      }
      blockDetaillist = {
        customerAssetSubList: [{
          assetTypeName: '年金保险'
        }]
      }
      async fetchDetail() {
        const res = await getAssetsInfo({
          assetTypeId: this.data.id,
          customerId: CommonMudule.customerId
        })
        if (res.statusCode === 0 && res.data) {
          this.dialog = true
          this.blockObj = addInsDefault(res.data)
        }
      }

      private addInfo(addData: AsDetailItem, blockIndex: number, asIndex: number) {
        this.blockObj.customerMemberAssetVOList[blockIndex].customerAssetSubList[asIndex].customerAssetList.push({
          deleted: false,
          assetTypeId: addData.assetTypeId,
          customerId: this.blockObj.customerId,
          customerMemberId: addData.customerMemberId,
          region: null,
          assetAmount: null,
          currencyId: 'currency-id-0001',
          currencyName: null,
          companyType: 'NoneSelfCompany',
          systemAssetType: addData.systemAssetType
        })
      }

      private removeInfo(blockIndex: number, index:number, idx: number) {
        this.blockObj.customerMemberAssetVOList[blockIndex].customerAssetSubList[index].customerAssetList[idx].deleted = true
      }

      private async confirm() {
        const res = await saveAssetItem(filterInsReqData(this.blockObj))
        if (res.statusCode === 0 && res.data) {
          this.$message({
            type: 'success',
            message: '保存成功'
          })
          this.dialog = false
          this.$emit('refresh')
        }
      }

      private handleClose() {
        if (this.loading) {
          return
        }
        this.loading = false
        this.dialog = false
      }

      cancelForm() {
        this.loading = false
        this.dialog = false
      }
      created() {
      }
}
