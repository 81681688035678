














































































































































































































import { Vue, Component } from 'vue-property-decorator'
import { analysText } from '@/utils/pageBottomText'
import moduleHeader from '@/components/moduleHeader/index.vue'
import PageButtomText from '@/components/pageBottomText/index.vue'
import tablePie from './coms/tablePie.vue'
import tableScatter from './coms/tableScatter.vue'
import { AnalyModule } from '@/store/modules/analy'
import AnalyMixins from '@/utils/mixins/analy-mixins'
import { mixins } from 'vue-class-component'
import tableBar from './coms/tableBar.vue'
import tableText from './coms/tableText.vue'
import tableTextPie from './coms/tableTextPie.vue'

@Component({
  name: 'analyChart',
  components: {
    moduleHeader,
    PageButtomText,
    tablePie,
    tableBar,
    tableText,
    tableTextPie,
    tableScatter
  }
})
export default class extends mixins(AnalyMixins) {
  analysText = analysText
  activities = [
    {
      content: '家庭资产总览',
      color: '#2179FF',
      type: 'primary',
      index: 0
    },
    {
      content: '财务指标分析',
      type: 'primary',
      index: 1,
      color: ''
    },
    {
      content: '资产配置分析',
      type: 'primary',
      index: 2,
      color: ''
    },
    {
      content: '家庭保障分析',
      type: 'primary',
      index: 3,
      color: ''
    }
  ]

  created() {
    this.initFetchData()
  }
  get totalAs() {
    return AnalyModule.totalAs
  }

  get analyObj() {
    return AnalyModule.analyObj
  }

  get analyObj2() {
    return AnalyModule.analyObj2
  }

  get childTeachObjArr() {
    return AnalyModule.childTeachObjArr
  }

  get famalySecuritObj() {
    return AnalyModule.famalySecuritObj
  }

  get childIllObjArr() {
    return AnalyModule.childIllObjArr
  }

  get asConfig() {
    return AnalyModule.asConfig
  }

  onBack() {
    this.$router.push({
      path: '/portfolio/portkyc/analy'
    })
  }
  goAchor(selector: any, activity: any) {
    this.activities[activity.index].color = '#2179FF'
    this.$el.querySelector(selector).scrollIntoView()
    this.activities.map((item) => {
      if (item.index !== activity.index) {
        item.color = ''
      }
    })
  }
}
