

































import { Component, Vue } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import drawCom from './two/drawCom.vue'
import drawInsCom from './two/drawIns.vue'
import { getAssetsList } from '@/api/asKYC'
import { MyCompony, OptArr, AssetsType, AslistItem, SeriesType } from '@/types/kyc'
import { CommonMudule } from '@/store/modules/common'
import { getcomplete } from '@/api/common'
import { cloneDeep } from 'lodash'
@Component({
  name: 'asKYC',
  components: {
    moduleHeader,
    drawCom,
    drawInsCom
  }
})

export default class extends Vue {
  private riskOption: OptArr = [{ label: '222', value: '333' }]
  private assetsList: AslistItem[] = []
 private selectList = []
 hadAsset = []
  datTemp = [{
    id: 'total-saving-asset-0001',
    tenantId: null,
    createTime: null,
    deleted: false,
    calFieldValue: null,
    assetTypeName: '存款',
    systemAssetType: 'Cash',
    parentId: 'root-asset-0001',
    seq: 1,
    level: 1,
    assetPerType: null,
    assetInputType: null,
    calFieldParams: null
  }, {
    id: 'total-insurance-0001',
    tenantId: null,
    createTime: null,
    createBy: null,
    createByName: null,
    deleted: false,
    calFieldValue: null,
    assetTypeName: '保险',
    systemAssetType: '',
    parentId: '-1',
    seq: 12,
    level: 1,
    assetPerType: null,
    assetInputType: 'MemberTab',
    calFieldParams: null
  }]
  created() {
    this.getTrue()
    // this.initFetch()
  }
  preSubmit() {
    // this.$router.push('/childTeach/index')
    this.$router.push({
      // name: 'childTeachOne',
      path: '/childTeach/index',
      params: {
        name: 'reset'
      }
    })
  }
  nextSubmit() {
    this.$router.push('/childTeach/child3')
  }
  async initFetch() {
    const data = cloneDeep(this.datTemp)
    data.map((item:any) => {
      const res = this.hadAsset.find((mt:any) => mt.id === item.id)
      // @ts-ignore
      item.visible = res.hadAsset
      return item
    })
    this.assetsList = data
    this.$forceUpdate()
  }
  async getTrue() {
    const res = await getcomplete({ customerId: CommonMudule.customerId })
    this.hadAsset = res.data
    this.initFetch()
  }
}
